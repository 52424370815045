@import "variables";
@import "banner.scss";
@import "sideMenu.scss";
@import "table.scss";

.App {
  padding: 20px 30px;
}

.header-title {
  font-weight: 700;
  line-height: 30px;
  font-size: 16px;
  color: $appTextBoldColor;
  margin-bottom: 0px;
}

.doc-bar {
  padding: 3px 20px;
  background-color: $appSecondaryColor;
  color: $appWhite;
  font-weight: 600;
  font-size: 16px;
  display: none;
}

.card {
  margin-bottom: 20px;
  border: none;
  box-shadow: $boxShadow;
  border-radius: 5px;
  & > .card-body {
    padding: 25px 30px 15px;
  }
}

.Toastify {
  & > .Toastify__toast-container {
    & .Toastify__toast {
      border-radius: 10px;
      padding: 10px 15px;
      & .Toastify__toast-body {
        font-size: 14px;
      }
      & .Toastify__close-button {
        opacity: 1;
      }
    }
  }
}

.detail-data {
  margin-bottom: 20px;
  font-size: 14px;
  & > dd {
    color: $appTextColor;
    margin-bottom: 0px;
    opacity: 0.8;
  }
  & > dt {
    color: $appTextBoldColor;
    font-weight: 500;
  }
}

.content-title-wrpper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > .header-title {
    font-weight: 700;
    line-height: 40px;
    font-size: 16px;
    color: $appTextBoldColor;
    margin-bottom: 0px;
  }
  & > .content-options {
    display: flex;
    justify-content: flex-end;
    & > .option {
      text-align: center;
      line-height: 40px;
      height: 40px;
      color: $appPrimaryColor;
      padding: 0px 10px;
      cursor: pointer;
      & svg {
        transform: scale(1.2);
      }
    }
  }
}

.add-transaction-wrapper {
  margin-bottom: 20px;
}
.add-account-wrapper {
  margin-bottom: 20px;
}
.add-asset-wrapper {
  margin-bottom: 20px;
}
.add-create-payment-wrapper {
  margin-bottom: 20px;
}

.content-wrapper {
  display: flex;
  & .admin-content-wrapper {
    flex-grow: 1;
    transition: 0.5s;
    padding: 20px 30px;
    background-color: $bodyBackgroundColor;
    min-height: calc(100vh - 60px);
    & .nav {
      &.nav-tabs {
        & > .nav-item {
          & > .nav-link {
            color: $appPrimaryColor !important;
          }
        }
        & + .tab-content {
          margin: 20px 0px 0px;
        }
      }
    }
  }
}

.asset-list-view-wrapper {
  display: flex;
  border-bottom: solid 1px $appBorderColor;
  min-height: 40px;
  align-items: center;
  padding: 10px 20px;
  &:last-child {
    border-bottom: none;
  }

  & > .asset-name {
    flex: 1;
    font-weight: 500;
  }

  & > .asset-desc {
    flex: 2;
  }
}

.asset-list-card {
  & .card-header {
    background-color: $appPrimaryColor;
    color: $appWhite;
  }
}

.bg-app {
  background-color: $appPrimaryColor;
  color: $appWhite;
}

.bg-spl-1 {
  background: linear-gradient(
    to right,
    $appPrimaryOpacity 0%,
    $appSecondaryOpacity 100%
  );
  color: $appWhite;
}

.cm-3 {
  margin: 30px;
}
.cmt-3 {
  margin-top: 30px !important;
}
.cp-3 {
  padding: 30px;
}

.display-title {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 400;
  color: $appTextColor;
}

.display-value {
  font-weight: 500;
  color: $appTextBoldColor;
  margin-bottom: 20px;
}

.header-title {
  color: $appTextColor;
  font-size: 14px;
  line-height: 30px;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
}

.react-datepicker-wrapper {
  display: block !important;
}

// .modal {
//   & .modal-body {
//     max-height: calc(100vh - 200px);
//     overflow: auto;
//   }
// }

.breadcrumb {
  padding: 0px 0px 5px;
  background-color: transparent !important;
  border-bottom: solid 1px $appBorderColor;
  border-radius: 0px;
  & > .breadcrumb-item {
    & > a {
      color: $appTextColor;
      text-decoration: none;
      font-size: 14px;
      &:hover {
        color: $appTextBoldColor;
      }
    }
  }
}

.file-display-wrapper {
  & .file-name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: 575.98px) {
  .not-in-mobile {
    display: none !important;
  }
  .in-mobile {
    display: block !important;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .not-in-mobile {
    display: none !important;
  }
  .in-mobile {
    display: block !important;
  }
}

.select-container {
  width: 180px !important;
  margin-top: 10px;
}

.dash-parent-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(576px, 1fr));
  grid-gap: 20px;
  & > .dash-card {
    width: 100%;
  }
}

.dash-count-parent-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: 20px;
  & > .dash-count-card {
    width: 100%;
    &.danger {
      & .card-body {
        & .card-title {
          &.title {
            color: #db4437;
          }
        }
      }
    }
    & .card-body {
      padding: 25px !important;
      & > .dash-card-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    & .card-title {
      margin-bottom: 0px;
      &.title {
        font-weight: bold;
        font-size: 18px;
      }
      &.caption {
        font-size: 14px;
        color: #777;
      }
    }

    & .card-count-value {
      & > p {
        color: #333;
        margin-bottom: 0px;
        font-size: 35px;
        font-weight: bold;
      }
    }
  }
}
