$appPrimaryColor:#f3b406;
$appSecondaryColor:  #0f6d06;
$appBlack: #000000;
$appWhite: #ffffff;
$appTextColor: #4f5c65;
$appTextBoldColor: #353e44;
$appLight: #e2e6ea;
$appDanger: #dd2c00;
$appButtonLight: #e0e0e0;
$iconColor: #b3b3b3;
$appBorderColor: #dee2e6;
$bodyBackgroundColor: #efefef;

$boxShadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
  rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

$appPrimaryOpacity: rgba(28, 131, 100, 0.5);
$appSecondaryOpacity: rgba(150, 237, 76, 0.7);