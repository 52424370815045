@import "variables";

.login-parent-wrapper {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    -45deg,
    $appSecondaryColor 50%,
    $appPrimaryColor 50%
  );
  & > .login-wrapper {
    width: 460px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: $boxShadow;
    padding: 20px 30px 30px;
    backdrop-filter: blur(50px);
    border-radius: 10px;
    background: linear-gradient(45deg, #ffffff69, transparent);
    & > .login-logo {
      width: 200px;
      margin-left: auto;
      margin-right: auto;
      & > img {
        width: 100%;
      }
    }

    & .login-header {
      margin-right: -30px;
      margin-left: -30px;
      background-color: $appSecondaryColor;
      & > .title {
        font-size: 18px;
        color: $appTextBoldColor;
        text-align: center;
        margin-top: 20px;
        padding: 10px 0px;
      }
    }

    & > .logo-field-wrapper {
      margin-left: auto;
      margin-right: auto;
      & .form-label {
        color: $appWhite;
        font-weight: bold;
      }
    }
  }
}
