@import "variables";

.navbar {
  & .navbar-brand {
    padding-top: 0px;
    padding-bottom: 0px;
    & > .navbar-brand-img {
      height: 40px;
    }
  }
  /*
    & .navbar-nav{
        & > .nav-item{
            min-width: 50px;
            & > .nav-link{
                padding: 8px 10px;
            }
        }
    }
    */
}

.my-navbar {
  padding: 0px 20px;
  display: flex;
  box-shadow: $boxShadow;
  background-color: $appPrimaryColor;
  & .my-navbar-brand {
    padding: 10px;
    border-right: solid 1px $appSecondaryColor;
    @media (max-width: 575.98px) {
      border-right: none;
    }
    @media (max-width: 767.98px) {
      border-right: none;
    }
    & .my-brand-logo {
      height: 40px;
      margin-right: 89px;
    }
  }
  & .my-navbar-toggler {
    line-height: 40px;
    color: $appWhite;
    padding: 10px 0px;
    margin-right: 20px;
    cursor: pointer;
  }
  & .my-profile-toggler {
    line-height: 36px;
    color: $appWhite;
    padding: 10px 0px;
    margin-right: 7px;
    margin-left: 18px;
    cursor: pointer;
  }

  & .my-navbar-items {
    & > .my-nav-item {
      line-height: 40px;
      padding: 10px 15px;
      &.banner-name {
        font-size: 20px;
        font-weight: 500;
        text-transform: capitalize;
        color: $appWhite;
        padding-left: 30px;
      }
    }
  }
  & .my-navbar-options {
    & > .dropdown {
      line-height: 35px;
      padding: 10px 15px;
      & .nav-link {
        padding: 0px;
        color: $appWhite;
        &:hover {
          color: $appWhite;
        }
      }

      & > .dropdown-menu {
        padding: 0px;
        font-size: 14px;
        & > .account-micro-view {
          padding: 0px 20px;
          & > p {
            margin: 15px 0px;
            line-height: 1.5;
          }
        }

        & > .dropdown-divider {
          margin: 0px;
        }

        & .logout-btn {
          color: $appDanger;
          background-color: $bodyBackgroundColor;
        }

        & .dropdown-item {
          font-size: 14px;
        }
      }
    }
  }
}

.images-box [type='file'] {
  font-size: 12px;
}

.images-box [type='file']::-webkit-file-upload-button {
  background: #cf1080;
  border: 2px solid #cf1080;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 10px;
  outline: none;
  padding: 5px 10px;
  margin-top: 2px;
  margin-left: 0px;
  text-transform: uppercase;
  transition: all 1s ease;
}