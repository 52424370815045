.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.white-color {
  background-color: #fff !important;
}

.dark-color {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  color: #fff !important;
}

@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.vehicle-requests-table .table thead th:first-child {
  padding-left: 29px;
}

.vehicle-requests-table .table thead th {
  font-size: 0.8425rem;
  padding-top: 16px;
  padding-bottom: 13px;
}

.vehicle-requests-table .table thead {
  background-color: #f3f6f9;
}

.vehicle-requests-table .table thead th:last-child {
  padding-right: 29px;
}

.vehicle-requests-table .table tbody tr td:last-child {
  padding-right: 29px;
}

.vehicle-requests-table .table tbody tr td:first-child {
  padding-left: 29px;
}

.vehicle-requests-table .table tbody tr td {
  --bs-table-accent-bg: #fff;
  padding: .6rem .5rem;
  font-size: 0.8325rem;
}

.vehicle-requests-table .table tbody {
  border-top: 1px solid currentColor;
}

.vehicle-requests-table .pagination {
  padding-left: 28px;
  padding-right: 28px;
  justify-content: right;
}

.vehicle-requests-table .pagination li:nth-child(3) {
  margin-left: 6px;
  margin-right: 6px;
}

.vehicle-requests-table .pagination .page-link span {
  color: #9b42cd;
}

.vehicle-requests-table .pagination .page-item.disabled .page-link span {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.card-header {
  display: flex;
}

.card-header .card-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
}

.card-header .btn {
  font-size: .8125rem;
}

.top-card .card-body {
  padding: 15px 30px 8px !important;
}

.top-card .card-body .form-group label {
  font-size: 0.8625rem;
}

.top-card .card-body .form-group input {
  font-size: 0.8325rem;
  height: 38px;
  box-shadow: none;
}

.top-card .card-body .form-group .btn-wrapper .btn {
  font-size: 0.8325rem;
  padding: .486rem .75rem;
}

.my-navbar-options {
  font-size: 0.8325rem;
}

.my-navbar-options .select-container .css-1insrsq-control {
  background-color: #fff;
  border-color: #fff;
}

.request-modal .modal-header {
  padding: 0.8rem 1rem;
}

.request-modal .modal-header .modal-title {
  font-size: 16px;
}

.request-modal .modal-body .alert-heading {
  font-size: 16px;
}

.request-modal .modal-body .input {
  font-size: 14px;
}

.edit-icon {
  cursor: pointer;
}

.request-modal .modal-body .add-request-btn {
  display: flex;
  margin: 0 auto;
  background-color: #f3b406;
  border-color: #f3b406;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: 500;
  padding: .455rem .95rem;
  justify-content: center;
}

.request-modal .modal-body .form-floating label {
  font-size: 14px;
}

.request-modal .requested-date input {
  font-size: 12px;
}

.request-modal .bottom-content .form-label {
  font-size: 14px;
}

.request-modal .bottom-content input {
  font-size: 14px;
}

.actions .bi-trash {
  color: rgb(220, 53, 69);
  cursor: pointer;
  margin-left: 10px;
}

.actions .bi-pencil-square {
  color: rgb(33, 37, 41);
  margin-left: 10px;
}

.top-row .display-title {
  font-weight: 500;
  font-size: 14px;
}

.top-row .display-value {
  font-size: 0.8525rem;
  font-weight: 300;
}

.list-group-item .file-name {
  font-size: 14px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.images-box p {
  margin-top: 9px;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 0;
}

.form-floating .form-control:required {
  border-left: 3px solid #dd2c00 !important;
}

.form-floating .form-select:required {
  border-left: 3px solid #dd2c00 !important;
}

.images-box .form-control:required {
  border-left: 3px solid #dd2c00 !important;
}

.warranty-btn span {
  margin-bottom: 0;
  line-height: 30px;
  font-size: 14px;
  color: #4f5c65;
}

.label-text {
  margin-bottom: 4px !important;
  line-height: 30px;
  font-size: 14px;
  color: #4f5c65;
}

.file-name {
  font-size: 13px;
  line-height: 1.4;
}

.hidden-service-completion-detail {
  display: none;
}

.service-completion-detail {
  display: block !important;
}

.custom-btn-btn {
  font-size: 10px !important;
  min-width: 74px !important;
  font-weight: 800 !important;
  margin-left: 17px;
}

.all-select-custom {
  color: #4f5c65;
  font-weight: 400;
  font-size: 0.8325rem;
}

.link-text {
  text-decoration: none;
}

.hiddenItem {
  display: none;
}

.menu-hidden {
  width: 0 !important;
  min-width: 0 !important;
}

.disabled-tr {
  pointer-events: none;
  opacity: 0.4;
}

.resell-scrap td {
  --bs-table-accent-bg: rgba(155, 66, 205, 0.6) !important;
  color: #fff !important;
}

.resell-scrap .actions .edit-icon {
  display: none;
}

.resell-scrap .actions .delete {
  display: none;
}

.factory-selects {
  width: 180px !important;
  max-height: 38px;
  min-height: 38px;
  margin-top: 11px;
  font-size: 13px !important;
  font-weight: 200 !important;
  box-shadow: none !important;
  outline: none !important;
}
td.actions {
  white-space: nowrap;
}
.react-icons.green path {
  stroke: #1d6f42;
}