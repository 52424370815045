@import "variables";

@mixin label-styles {
  margin-bottom: 0px;
  line-height: 30px;
  font-size: 14px;
  color: $appTextColor;
}

.form-group {
  height: 90px;
  & .form-label {
    @include label-styles();
  }
  & label {
    @include label-styles();
  }
  & .form-control {
    height: 40px;
    line-height: 40px;
    padding: 0px 15px;
    font-size: 14px;
    color: $appTextColor;
    &:required {
      border-left: solid 3px $appDanger !important;
    }
    &.required {
      border-left: solid 3px $appDanger !important;
    }
    &.is-invalid {
      & + .invalid-feedback {
        display: block;
      }
    }
  }

  & .invalid-feedback {
    color: $appDanger;
    font-size: 12px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .err-message {
    color: $appDanger;
    font-size: 12px;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.select-form-control {
  & .select-form__value-container {
    flex-wrap: nowrap !important;
  }
  & .select-form__placeholder {
    font-size: 14px;
  }
  &.required {
    & > .select-form__control {
      border-left: solid 3px $appDanger !important;
    }
  }
}

.custom-selection-wrapper {
  &.required {
    border-left: solid 3px $appDanger !important;
  }
}

.react-datepicker-wrapper {
  & ~ .invalid-feedback {
    display: block;
  }
}

.app-toggle-switch {
  position: relative;
  display: block;
  width: calc(47px + 47px + calc(50px - 47px) + calc(50px - 47px));
  height: 40px;
  border: solid 1px #b3b3b3;
  border-radius: 3px;
  z-index: 0;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  cursor: pointer;
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  & > input {
    opacity: 0;
    width: 0;
    height: 0;
    & ~ .slide-off {
      z-index: 1;
      bottom: 2px;
      font-size: 12px;
      border-radius: 3px;
      padding: 0px 5px;
      position: absolute;
      text-align: center;
      overflow-x: hidden;
      min-width: 47px;
      max-width: 47px;
      text-overflow: ellipsis;
      min-height: 34px;
      line-height: 34px;
      background-color: transparent;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -ms-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
      left: 2px;
      color: #fff;
      font-weight: bold;
    }
    & ~ .slide-on {
      z-index: 1;
      bottom: 2px;
      font-size: 12px;
      border-radius: 3px;
      padding: 0px 5px;
      position: absolute;
      text-align: center;
      overflow-x: hidden;
      min-width: 47px;
      max-width: 47px;
      text-overflow: ellipsis;
      min-height: 34px;
      line-height: 34px;
      background-color: transparent;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -ms-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
      right: 2px;
      color: #58595b;
      font-weight: normal;
    }
    & ~ .slide-highlighter {
      z-index: 1;
      bottom: 2px;
      font-size: 12px;
      border-radius: 3px;
      padding: 0px 5px;
      position: absolute;
      text-align: center;
      overflow-x: hidden;
      min-width: 47px;
      max-width: 47px;
      text-overflow: ellipsis;
      min-height: 34px;
      line-height: 34px;
      background-color: transparent;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -ms-transition: 0.4s;
      -o-transition: 0.4s;
      transition: 0.4s;
      left: 2px;
      z-index: 0;
      background-color: #58595b;
    }

    &:checked {
      & ~ .slide-off {
        color: #58595b;
        font-weight: normal;
      }
      & ~ .slide-on {
        color: #fff;
        font-weight: bold;
      }
      & ~ .slide-highlighter {
        -webkit-transform: translateX(47px);
        -moz-transform: translateX(47px);
        -ms-transform: translateX(47px);
        -o-transform: translateX(47px);
        transform: translateX(47px);
        background-color: #36b07f;
      }
    }
  }
}

.form-file-drop-content {
  flex: 1;
  white-space: nowrap;
  height: 38px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;

  // & .remove-file {
  //   width: 20px;
  // }
}

.remove-file {
  position: absolute;
  right: 45px;
  top: 31px;
  height: 38px;
  width: 30px;
  text-align: center;
  padding: 5px 0px;
  opacity: 0.2;
  transition: ease-in 0.2s;
  &:hover {
    cursor: pointer;
    color: $appDanger;
    opacity: 1;
  }
}
